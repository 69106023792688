<template>
  <div class="ml-11">
    <div class="flex justify-between">
      <div class="flex align-center">
        <template>
          <icon-dots />
        </template>
        <h4 class="ml-1">
          {{ parentTitle + "." + (stepIndex+1) }}
        </h4>
      </div>
      <v-menu
        offset-y
        bottom
        class="rounded-lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            depressed
            class="property"
            v-bind="attrs"
            :plain="true"
            icon
            v-on="on"
          >
            <v-icon size="24px">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-card
          rounded="8"
          elevation="0"
        >
          <v-list>
            <v-list-item
              :key="1"
              @click="$emit('duplicate-child')"
            >
              <div class="d-flex align-center">
                <duplicateIcon />
              </div>
              <v-list-item-title class="pl-3">
                {{ $t('duplicate') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="2"
              @click="$emit('delete-child', stepIndex)"
            >
              <div class="d-flex align-center">
                <removeIcon />
              </div>
              <v-list-item-title class="color-red pl-3">
                {{ $t('remove') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div>
      <div class="text-left">
        <v-label class="fs-14px text-theme-label font-weight-medium">
          {{ $t('stepDescription') }}
        </v-label>
      </div>

      <v-textarea
        v-model="description"
        auto-grow
        placeholder="Step description"
        background-color="#F9F9FB"
        class="round-8 mt-1 mb-2 pt-0"
        :rules="descriptionValidation"
        @input="$emit('description-change-child', description, stepIndex)"
      />

      <div class="text-left">
        <v-label class="fs-14px text-theme-label font-weight-medium">
          {{ $t('expectedResult') }}
        </v-label>
      </div>
      <v-textarea
        v-model="expectedResult"
        auto-grow
        placeholder="Expected result"
        background-color="#F9F9FB"
        class="round-8 mt-1 mb-2 pt-0"
        :rules="resultValidation"
        @input="$emit('result-change-child', expectedResult, stepIndex)"
      />
    </div>
  </div>
</template>
<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import removeIcon from '@/assets/svg/remove.svg';
import duplicateIcon from '@/assets/svg/duplicate.svg';
import { requiredFieldValidationRules } from "@/utils/validation";

export default {
  name: 'ChildStep',
  components: {
    iconDots,
    removeIcon,
    duplicateIcon,
  },
  props: {
    childStep: Object,
    parentTitle: String,
    stepIndex: Number,
  },
  data() {
    return {
      name: "",
      description: "",
      this: "",
      expectedResult: "",
      descriptionValidation: requiredFieldValidationRules(this),
      resultValidation: requiredFieldValidationRules(this),
    }
  },
  created() {
    this.description = this.childStep.description;
    this.expectedResult = this.childStep.expectedResult;
  },
}
</script>
